const defaultTime = '1500';
let response,
	results,
	king,
	friend,
	enemy,
	strength,
	support,
	max_strength,
	new_strength,
	ice_strength,
	fire_strength,
	new_fire_strength;

document.addEventListener('DOMContentLoaded', function() {
	document.documentElement.style.setProperty('--default-time', defaultTime + 'ms');
	changePhase(phase);
	if (document.querySelector('.quiz')) {
		let userData = {
			house: localStorage['house'],
			friend: localStorage['friend'],
			enemy: localStorage['enemy'],
			question_1: localStorage['question_1'],
			question_2: localStorage['question_2']
		};

		let activeHouse;
		let activeChoice;
		const title = document.querySelector('#title');
		const houses = document.querySelectorAll('.houses__house');
		const choices1_1 = document.querySelectorAll('.choices1-1__label');
		const choices1_2 = document.querySelectorAll('.choices1-2__label');
		const choices1_3 = document.querySelectorAll('.choices1-3__label');
		const choices2 = document.querySelectorAll('.choices2__label');
		const button1 = document.querySelector('#button-phase-1');
		const button2 = document.querySelector('#button-phase-2');

		houses.forEach((el) => {
			el.addEventListener('click', function() {
				document.querySelectorAll('.houses__house.active')
					.forEach(el => el.classList.remove('active'));
				activeHouse = this;
				this.classList.add('active');
				button1.disabled = false;
			});
		});
		choices1_1.forEach((el) => {
			el.addEventListener('click', function() {
				document.querySelectorAll('.choices1-1__label.active')
					.forEach(el => el.classList.remove('active'));
				activeChoice = this;
				this.classList.add('active');
				button1.disabled = false;
			});
		});
		choices1_2.forEach((el) => {
			el.addEventListener('click', function() {
				document.querySelectorAll('.choices1-2__label.active')
					.forEach(el => el.classList.remove('active'));
				activeChoice = this;
				this.classList.add('active');
				button1.disabled = false;
			});
		});
		choices1_3.forEach((el) => {
			el.addEventListener('click', function() {
				document.querySelectorAll('.choices1-3__label.active')
					.forEach(el => el.classList.remove('active'));
				activeChoice = this;
				this.classList.add('active');
				button1.disabled = false;
			});
		});

		button1.addEventListener('click', function() {
			this.disabled = true;
			if (!localStorage['house']) {
				activeHouse.remove();
				localStorage['stepCounter'] ++;
				localStorage['house'] = activeHouse.dataset.name;
				userData.house = activeHouse.dataset.name;
				title.innerText = translations.question_2;
				document.querySelectorAll('b.home').forEach(el => {
					el.textContent = localStorage['house'];
				});
				nextStepAnimation();
			}
			else if (!localStorage['friend']) {
				activeHouse.remove();
				localStorage['stepCounter'] ++;
				localStorage['friend'] = activeHouse.dataset.name;
				userData.friend = activeHouse.dataset.name;
				title.innerText = translations.question_3;
				document.querySelector('.houses').classList.add('houses_three-on-row');
				nextStepAnimation();
			}
			else if (!localStorage['enemy']) {
				activeHouse.remove();
				localStorage['stepCounter'] ++;
				localStorage['enemy'] = activeHouse.dataset.name;
				userData.enemy = activeHouse.dataset.name;
				nextStep();
				nextStepAnimation();
			}
			else if (!localStorage['answer1_1']) {
				localStorage['stepCounter'] ++;
				localStorage['answer1_1'] = activeChoice.dataset.choice;
				nextStep();
				nextStepAnimation();
			}
			else if (!localStorage['answer1_2']) {
				localStorage['stepCounter'] ++;
				localStorage['answer1_2'] = activeChoice.dataset.choice;
				nextStep();
				nextStepAnimation();
			}
			else if (!localStorage['answer1_3']) {
				localStorage['stepCounter'] ++;
				localStorage['answer1_3'] = activeChoice.dataset.choice;
				nextStep();
				nextStepAnimation();
				const strengths = {
					1: {1: 60, 2: 80, 3: 20, 4: 40},
					2: {1: 50, 2: 60, 3: 100, 4: 30},
					3: {1: 70, 2: 70, 3: 20, 4: 20},
					4: {1: 40, 2: 50, 3: 10, 4: 20}
				};

				let strength = strengths[localStorage['answer1_1']][localStorage['answer1_2']];

				if (strength > 50) {
					const multipliers = {1: 1.2, 2: 1, 3: 1.5, 4: 1};
					strength *= multipliers[localStorage['answer1_3']];
				} else {
					const multipliers = {1: 0.8, 2: 1.6, 3: 0.5, 4: 1};
					strength *= multipliers[localStorage['answer1_3']];
				}
				userData.question_1 = strength;
				document.querySelector('.message-container b').innerHTML = userData.house;

				document.querySelector('.loading').style.display = "block";
				function reply(tryNum) {
					fetch('/users', {
						method: 'POST',
						body: JSON.stringify(userData),
						headers: Object.assign({
							'Content-Type': 'application/json'
						}, fetchHeaders())
					})
						.then(response => {
							if (!response.ok) {
								throw new Error('Network response was not ok');
							}
							document.querySelector('.loading').style.display = "none";
							return response.json().then(data => {
								localStorage['userId'] = data.id;
							});
						})
						.catch(error => {
							console.log('There was a problem with the fetch operation:', error);
							if (tryNum > 0)
								setTimeout(() => reply(tryNum - 1), 1000);
							else {
								localStorage['errorConsole'] = error;
								localStorage['error'] = 'error';
								location.assign(window.location.href + 'loss');
							}
						});
				}

				reply(5);
			}
		});

		let question_2 = null;
		choices2.forEach((el) => {
			el.addEventListener('click', function() {
				question_2 = this.dataset.choice;
				button2.disabled = false;
			});
		});
		button2.addEventListener('click', function() {
			if (!localStorage['userId'])
				return;

			localStorage['stepCounter'] = 8;
			fetch(`/users/${localStorage['userId']}`, {
				method: 'PUT',
				headers: Object.assign({
					'Content-Type': 'application/json'
				}, fetchHeaders()),
				body: JSON.stringify({ question_2: question_2 })
			});
			document.querySelectorAll('.phase.active').forEach((el, i) => {
				el.classList.remove('active');
				el.nextSibling.classList.add('active');
			});
			nextStepAnimation();
		});
	}
	if (document.querySelector('.screen')) {
		// phase 2
		document.getElementById('btn-get-strength').addEventListener('click', function () {
			const timeGetStrength = '3'; // in seconds

			this.disabled = true;
			getStrength(timeGetStrength);
		});
		document.getElementById('btn-get-king').addEventListener('click', function () {
			this.disabled = true;
			scaleAnimation(document.querySelector('.houses_crown'), defaultTime);
			scaleAnimation(document.querySelector('.screen__big .houses__house'), defaultTime);
			getKing();
		});
		document.getElementById('btn-get-friend').addEventListener('click', function () {
			this.disabled = true;
			scaleAnimation(document.querySelector('.houses_hand'), defaultTime);
			getFriend();
		});
		document.getElementById('btn-get-enemy').addEventListener('click', function () {
			const timeGetStrength = '3'; // in seconds

			this.disabled = true;
			getEnemy(timeGetStrength);
		});

		// phase 3
		document.getElementById('btn-get-new-strength').addEventListener('click', function () {
			const timeGetStrength = '3'; // in seconds

			this.disabled = true;
			async function processHouse(house) {
				const houseDiv = document.querySelector(`[data-house='${house}']`);
				const strengthEl = houseDiv.querySelector('.progress__bar');
				houseDiv.querySelector('.houses__img').style.transition = `${timeGetStrength}s`;
				houseDiv.querySelector('.houses__dead').style.transition = `${timeGetStrength}s`;
				houseDiv.querySelector('.houses__crown').style.transition = `${timeGetStrength}s`;
				houseDiv.querySelector('.houses__hand').style.transition = `${timeGetStrength}s`;
				houseDiv.querySelector('.houses__enemy').style.transition = `${timeGetStrength}s`;
				strengthEl.style.transition = `${timeGetStrength}s`;
				let oldStrength = strength[house]
				let newStrength = new_strength[house]
				if (house === enemy) {
					oldStrength = houseDiv.dataset.newStrength;
				}
				if (newStrength < 0)
					newStrength = 0;
				strengthEl.style.width = widthStrength(newStrength);
				countAnimation(
					oldStrength,
					newStrength,
					houseDiv,
					timeGetStrength * 1000,
					oldStrength > newStrength ? '-' : '+'
				);
				if (new_strength[house] < 10) {
					houseDiv.classList.add('houses_dead');
				}
				if (support[house] == 0) {
					houseDiv.classList.add('houses_piker');
				}
				else {
					houseDiv.classList.add('houses_battle');
				}
			}

			(async function iterateHouses() {
				for (const house of housesHelper) {
					await processHouse(house);
					await new Promise((resolve) => setTimeout(resolve, strength[house] == '0' ? 0 : timeGetStrength * 1000));
				}
			})();
		});
		document.getElementById('btn-get-final').addEventListener('click', function () {
			const timeGetStrength = '3'; // in seconds

			this.disabled = true;
			(async () => {
				response = await fetch('/users/results');
				results = await response.json();
				ice_strength = results.ice_strength;
				getResults(new_fire_strength, ice_strength, timeGetStrength, timeGetStrength * 2)
			})();
		});
	}
});
function getResults (fire, ice, time, countTime) {
	const battleIce = document.querySelector('.battle__ice');
	const battleFire = document.querySelector('.battle__fire');
	const battleIceStrength = document.querySelector('.battle__ice-strength');
	const battleFireStrength = document.querySelector('.battle__fire-strength');
	let iceStrengthWidth;
	let fireStrengthWidth;
	let maxPossibleValue;

	if (ice > fire) {
		iceStrengthWidth = '75%';
		fireStrengthWidth = '25%';
		maxPossibleValue = ice;
	}
	else {
		iceStrengthWidth = '25%';
		fireStrengthWidth = '75%';
		maxPossibleValue = fire;
	}
	battleIce.style.transition = `${time}s`;
	battleFire.style.transition = `${time}s`;
	battleIceStrength.style.transition = `${time}s cubic-bezier(0.03, 0.34, 0.25, 1) 0s`;
	battleFireStrength.style.transition = `${time}s cubic-bezier(0.03, 0.34, 0.25, 1) 0s`;

	finalCountAnimation(battleIceStrength, ice);
	finalCountAnimation(battleFireStrength, fire);
	function finalCountAnimation(progressValue, stopValue) {
		progressValue.style.opacity = '1';
		progressValue.style.transform = 'scale(1)';
		let startValue = 0;
		let interval = setInterval(() => {
			startValue = startValue + 1;
			if (startValue >= stopValue) {
				startValue = stopValue;
				clearInterval(interval);
				battleIce.style.width = iceStrengthWidth;
				battleFire.style.width = fireStrengthWidth;
			}
			progressValue.innerHTML = startValue;
		},  Math.round(countTime * 1000 / maxPossibleValue));
	}

}
function getStrength (timeGetStrength = 0) {
	async function processHouse(house) {
		const houseDiv = document.querySelector(`[data-house='${house}']`);
		const strengthEl = houseDiv.querySelector('.progress__bar');
		const houseImg = houseDiv.querySelector('.houses__img');
		houseDiv.classList.add('houses_active');
		houseDiv.querySelector('.houses__dead').style.transition = `${timeGetStrength}s`;
		houseImg.style.transition = `${timeGetStrength}s`;
		strengthEl.style.transition = `${timeGetStrength}s`;
		strengthEl.style.width = widthStrength(strength[house]);
		if (strength[house] == '0') {
			houseDiv.classList.add('houses_dead');
		}
		else {
			countAnimation(
				0,
				strength[house],
				houseDiv,
				timeGetStrength * 1000,
				'+'
			);
		}

		if (house === king) {
			houseDiv.classList.add('houses_crown');
			document.querySelector('.screen__big .houses__img').src = houseDiv.querySelector('.houses__img').src;
		}
		else if (house === friend) {
			houseDiv.classList.add('houses_hand');
		}
		else if (house === enemy) {
			houseDiv.classList.add('houses_enemy');
			houseDiv.dataset.strength = strength[house];
			houseDiv.dataset.newStrength = Math.round(strength[house] / 2);
		}
	}

	(async function iterateHouses() {
		for (const house of housesHelper) {
			const timeForDead = timeGetStrength / 2 * 1000;
			const timeout = strength[house] == '0' ? timeForDead : timeGetStrength * 1000 + timeForDead;
			await processHouse(house);
			await new Promise((resolve) => setTimeout(resolve, timeout));
		}
		if (phase == '3') {
			getKing();
			getFriend();
			getEnemy();
		}
	})();
}
function getKing () {
	document.getElementById('result-screen').classList.add('show_crown');
}
function getFriend () {
	document.getElementById('result-screen').classList.add('show_hand');
}
function getEnemy (timeGetStrength = 0) {
	document.getElementById('result-screen').classList.add('show_enemy');
	const enemyDiv = document.querySelector('.houses_enemy');
	const enemyStrength = enemyDiv.dataset.strength;
	const enemyNewStrength = enemyDiv.dataset.newStrength;
	enemyDiv.querySelector('.progress__bar').style.width = widthStrength(enemyNewStrength);
	enemyDiv.querySelector('.progress__bar').style.transition = `${timeGetStrength}s`;
	countAnimation(
		enemyStrength,
		enemyNewStrength,
		enemyDiv,
		timeGetStrength * 1000,
		'-'
	);
}
function countAnimation(startValue, stopValue, el, time, action) {
	const progressBg = el.querySelector('.progress__bg');
	const progressBar = el.querySelector('.progress__bar');
	const progressValue = el.querySelector('.progress__value');
	const img = el.querySelector('.houses__img');
	img.style.transform = 'scale(1.2)';
	if (action == '-') {
		progressBg.style.filter = 'drop-shadow(0px 0px 0.5vw #792901)';
		progressBar.style.background = '#792901';
		progressValue.style.color = '#792901';
	}
	else {
		progressBg.style.filter = 'drop-shadow(0px 0px 0.5vw #7e6e01)';
	}
	if (time == 0 || startValue == stopValue)	{
		progressValue.innerHTML = stopValue;
		progressBg.style.filter = 'none';
		img.style.transform = 'scale(1)';
		progressBar.style.background = '#7e6e01';
		progressValue.style.color = 'white';
		return;
	}
	let interval = setInterval(() => {
		startValue = action == '+' ? startValue + 1 : startValue - 1;
		progressValue.innerHTML = startValue;
		if (startValue == stopValue) {
			progressBg.style.filter = 'none';
			img.style.transform = 'scale(1)';
			progressBar.style.background = '#7e6e01';
			progressValue.style.color = 'white';
			clearInterval(interval);
		}
	}, Math.round(time / Math.abs(stopValue - startValue)));
}
function scaleAnimation(el, time) {
	el.classList.add('houses_battle');
	setTimeout(() => {
		el.classList.remove('houses_battle');
	}, time);
}
function nextStepAnimation() {
	let section = document.querySelector('.phase.active');
	section.animate(
		[{ opacity: 0 }, { opacity: 1 }],
		1000
	);
}
function nextStep(index) {
	let section;
	if (index) {
		document.querySelectorAll('.phase.active section').forEach((el, i) => {
			el.classList.remove('active');
			if (i == index)
				el.classList.add('active');
		});
	}
	else {
		section = document.querySelector('.phase.active section.active');
		section.classList.remove('active');
		section = section.nextElementSibling;
		section.classList.add('active');
	}

}
export function changePhase(phase) {
	if (concertId !== +localStorage['concertId']) {
		localStorage.clear();
		localStorage['concertId'] = concertId;
	}
	if (document.querySelector('.quiz') && (localStorage['error'] === 'error' || phase >= 2 && !localStorage['userId'])) {
		location.assign(window.location.href + 'loss');
	}
	document.querySelectorAll('.phase').forEach((el, i) => {
		el.classList.remove('active');

		if (el.classList.contains('phase-2-3') && (phase == '2' || phase == '3')) {
			el.classList.add('active');
			if (phase == '3') {
				el.querySelector('.screen').classList.add('phase-3');
				el.querySelector('.screen').classList.remove('phase-2');
			}
			else {
				el.querySelector('.screen').classList.add('phase-2');
				el.querySelector('.screen').classList.remove('phase-3');
			}
		}
		else {
			if (i == phase)
				el.classList.add('active');
		}
	});
	if (document.querySelector('.screen')) {
		const el = document.querySelector('.admin-section');
		el.classList.remove('phase-2', 'phase-3');
		el.classList.add(`phase-${phase}`);
	}

	if (phase >= 2) {
		(async () => {
			response = await fetch('/users/results');
			results = await response.json();
			king = results.house;
			friend = results.friend;
			enemy = results.enemy;
			strength = results.strength;
			support = results.support;
			max_strength = results.strength[king];
			new_strength = results.results;
			ice_strength = results.ice_strength;
			fire_strength = results.fire_strength;
			new_fire_strength = results.new_fire_strength;
			localStorage['king'] = king;
			localStorage['newStrengthHome'] = new_strength[localStorage['house']];
			localStorage['homeSupport'] = results.support[localStorage['house']];

			if (document.querySelector('.screen') && (phase == '2' || phase == '3')) {
				document.querySelector('.fire b').innerText = fire_strength;
				document.querySelector('.new-fire b').innerText = new_fire_strength;
				document.querySelector('.ice b').innerText = ice_strength;
			}
			if (document.querySelector('.screen') && phase == '3') {
				if (phase == '3') {
					getStrength();
				}
			}
			if (document.querySelector('.quiz')) {
				document.querySelectorAll('b.king').forEach(el => {
					el.textContent = localStorage['king'];
				});
		 	if (phase == '3' && localStorage['userId']) {
					const finalTextEl = document.querySelector('.final-text');
					const newStrengthHome = localStorage['newStrengthHome'];
					const homeSupport = localStorage['homeSupport'];
					const fireWins = new_fire_strength > ice_strength;
					finalTextEl.innerText = '';
					if (newStrengthHome < 10)
						finalTextEl.innerText = translations.final_home_dead;
					else if (fireWins && homeSupport == '1')
						finalTextEl.innerText = translations.final_win_1;
					else if (fireWins)
						finalTextEl.innerText = translations.final_win_2;
					else if (!fireWins && homeSupport == '1')
						finalTextEl.innerText = translations.final_lost_1;
					else
						finalTextEl.innerText = translations.final_lost_2;
				}
			}
		})();
	}

	if (document.querySelector('.quiz')) {
		if (!parseInt(localStorage['stepCounter']) || localStorage['stepCounter'] == undefined)
			localStorage['stepCounter'] = 1;

		let stepCounter = +localStorage['stepCounter'];

		document.querySelectorAll('b.home').forEach(el => {
			el.textContent = localStorage['house'];
		});

		if (phase == '1') {
			const title = document.querySelector('#title');
			if (stepCounter == 2) {
				title.innerText = translations.question_2;
				document.querySelector(`[data-name='${localStorage['house']}']`).remove();
			}
			else if (stepCounter == 3) {
				title.innerText = translations.question_3;
				document.querySelector(`[data-name='${localStorage['house']}']`).remove();
				document.querySelector(`[data-name='${localStorage['friend']}']`).remove();
			}
			else if (stepCounter >= 4 && stepCounter <= 7) {
				nextStep(stepCounter - 3);
			}
			else if (stepCounter > 7) {
				nextStep(4);
			}
		}
		else if (phase == '2' && stepCounter == '8') {
			document.querySelectorAll('.phase.active').forEach((el, i) => {
				el.classList.remove('active');
				el.nextSibling.classList.add('active');
			});
		}
	}
}
function widthStrength(houseStrength) {
	return houseStrength * 100 / max_strength + '%'
}
function fetchHeaders() {
	return { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content };
}
