import consumer from "./consumer"
import {changePhase} from "../packs/main"
consumer.subscriptions.create("ConcertChannel", {
  connected() {
    console.log('connected')
  },

  disconnected() {
    console.log('disconnected')
  },

  received(data) {
    const phase = data.body;
    switch (phase) {
      case '0':
        changePhase(phase)
        break;
      case '1':
        changePhase(phase)
        break;
      case '2':
        changePhase(phase);
        break;
      case '3':
        changePhase(phase);
        break;
    }
    console.log(data);
  }
});